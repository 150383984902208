import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import Awareness from "./components/Awareness";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Awareness />} />,
  )
)

export default function App() {
  return (
    <RouterProvider router={router} />
  );
}
